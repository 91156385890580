import React from 'react';

import './UpdateForm.css';

import InputNumber from '../InputNumber/InputNumber';

class UpdateForm extends React.Component {

    constructor(props) {
        super(props);

        const values = []

        for (let i = 0; i < props.form.length; i++) {
            if (props.form[i].value !== undefined & props.form[i].value !== null) {
                values.push(props.form[i].value);
            } else
                values.push("");
        }

        this.state = {
            values: values
        };

        this.submitButton = React.createRef();
    }

    handleClick = (e) => {
        e.preventDefault();
        const { form } = this.props;
        const { values } = this.state
        const cleanValues = [];

        this.props.handleClick(values);

        for (let i = 0; i < form.length; i++) {
            if (form[i].type === "password") {
                cleanValues.push("");
            } else {
                cleanValues.push(values[i]);
            }
        }

        this.setState({
            values: cleanValues
        });
    }

    handleChange = (value, id) => {
        const { values } = this.state;

        const newValues = values.map((field, index) => {
            if (index === id)
                return value;
            return field;
        })

        this.setState({
            values: newValues
        });
    }

    handleChangeNumber = (value, keyName) => {
        this.props.handleChange(value, keyName);
    }

    render() {
        const { title, description, form, message, formOnSubmit, buttonStyle } = this.props;
        const { values } = this.state;

        return (
            <section className="update-form-section">
                <div className="description-container">
                    <h2>{title}</h2>
                    {description}
                    <ul>
                        {form.map((field, index) => {
                            return <li key={index} ><span>{field.label}</span> : {field.description}</li>
                        })}
                    </ul>

                    <p>
                        <button className={"submit-button " + buttonStyle} type="submit" ref={this.submitButton} onClick={this.handleClick}>Submit</button>
                        <span style={message.style}>{message.content}</span>
                    </p>

                </div>

                <form onSubmit={formOnSubmit ? this.handleClick : (e) => e.preventDefault()}>
                    {form.map((field, index) => {
                        const name = field.label.toLowerCase().replace(" ", "-");

                        if (field.type === "number") {
                            return (
                                <InputNumber
                                    key={index}
                                    name={name}
                                    keyName={field.keyName}
                                    label={field.label}
                                    value={field.value}
                                    handleClick={this.handleChangeNumber}
                                />
                            );
                        } else if (field.type === "text" | field.type === "password") {
                            return <div key={index} className="standard-field-container">
                                <label htmlFor={name}>{field.label}</label>
                                <input className={field.className ? field.className : ""} name={name} type={field.type} onChange={(e) => this.handleChange(e.target.value, index)} value={values[index]} />
                            </div>
                        }

                        return <div key={index}>Unknown input type</div>
                    })}
                    <input type="submit" style={{ display: "none" }} />
                </form>
            </section>
        );
    }
}

export default UpdateForm;