import React from 'react';

import './HeaderMenu.css';

class HeaderMenu extends React.Component {

    handleLogout = (e) => {
        e.preventDefault();
        this.props.handleLogout();
    }

    render() {
        const { username } = this.props

        return (
            <header className="pages-header">
                <h1><a href="/">Learn Your Tables</a></h1>
                <nav>
                    <ul>
                        <li><a href="/practice"  >Practice</a></li>
                        <li><a href="/settings">{username}</a></li>
                        <li><a href="/about">About</a></li>
                        <li><a href="/"onClick={(e) => this.handleLogout(e)} >Logout</a></li>
                    </ul>
                </nav>
            </header>
        );
    }
}

export default HeaderMenu;