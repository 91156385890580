import * as tools from './general-tools';
import { API_ENDPOINT } from '../const';

export function login(details) {
    const formBody = tools.generateBody_xwwwformurlencoded(details);

    return fetch(API_ENDPOINT + "/api/users/login", {
        method: "POST",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: formBody
    }).then((response) => {
        return response.json();
    }).then(data => {
        if (data.error === undefined) {
            localStorage.setItem('token', data.token);
            return {
                status: "success",
                response: data.token
            };
        } else {
            return {
                status: "error",
                response: data.error
            };
        }
    })
}

export function register(details) {
    const formBody = tools.generateBody_xwwwformurlencoded(details);

    return fetch(API_ENDPOINT + "/api/users/register", {
        method: "PUT",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
        body: formBody
    }).then((response) => {
        return response.json();
    }).then(data => {
        if (data.error === undefined) {
            return {
                status: "success",
            };
        } else {
            return {
                status: "error",
                response: data.error
            };
        }
    })
}

export function getProfil(token) {
    return fetch(API_ENDPOINT + "/api/users/profile", {
        method: 'GET',
        headers: {
            "Authorization": "Bearer " + token
        }
    }).then(response => {
        if (response.status === 202)
            return response.json();
        return null;
    })
}

export function getMultiplicationPrefs(token) {
    return fetch(API_ENDPOINT + "/api/users/prefs/multiplication", {
        method: 'GET',
        headers: {
            "Authorization": "Bearer " + token
        }
    }).then(response => {
        if (response.status === 202)
            return response.json();
        return null;
    })
}

export function updatePassword(token, raw) {
    return fetch(API_ENDPOINT + "/api/users/update-my-password", {
        method: 'PUT',
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        },
        body: raw
    }).then(response => {
        return response.json();
    })
}

export function updateMultiplicationPrefs(token, raw) {
    return fetch(API_ENDPOINT + "/api/users/prefs/multiplication", {
        method: 'PUT',
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        },
        body: raw
    }).then(response => {
        return response.json();
    })
}

export function deleteAccount(token, raw) {
    return fetch(API_ENDPOINT + "/api/users/delete-my-account", {
        method: 'DELETE',
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        },
        body: raw
    }).then(response => {
        return response.json();
    })
}

export function createNewDataset(token, raw) {
    return fetch(API_ENDPOINT + "/api/data/datasets", {
        method: 'POST',
        headers: {
            "Authorization": "Bearer " + token,
            "Content-Type": "application/json"
        },
        body: raw
    }).then(response => {
        return response.json();
    })
}