import React from 'react';
import * as tools from '../../Utils/general-tools';
import * as fetchRequest from '../../Utils/fetch-request';

import personfill from '../../Images/Icons/person-fill.svg';
import keyfill from '../../Images/Icons/key-fill.svg';

import InputIcon from '../InputIcon/InputIcon';
import ButtonForm from '../ButtonForm/ButtonForm';

import './LoginForm.css';

class LoginForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            login: "",
            password: ""
        };

        this.p = React.createRef();
    }

    handleInput = (value, name) => {
        this.setState({
            [name]: value
        });
    }

    login = () => {
        const { login, password } = this.state;
        
        if (tools.isNull(login) | tools.isNull(password)) {
            this.p.current.innerText = "Login/Password field(s) must be filled";
            return;
        }
        
        const details = { login: login, password: password };

        fetchRequest.login(details).then((data) => {
            if (data.status === "success")
                this.props.handleLogin(data.response);
            else {
                this.p.current.innerText = data.response;
            }
        });
    }

    render() {
        const { login, password } = this.state;

        return (
            <div className="container">
                <div className="form-container">
                    <h1>Sign In</h1>
                    <p>Fill out this form</p>
                    <form>
                        <InputIcon
                            name="login"
                            type="text"
                            value={login}
                            icon={personfill}
                            handleChange={this.handleInput}
                        >Login</InputIcon>
                        <InputIcon
                            name="password"
                            type="password"
                            value={password}
                            icon={keyfill}
                            handleChange={this.handleInput}
                        >Password</InputIcon>

                        <p ref={this.p} className="error-message"></p>

                        <ButtonForm
                            handleClick={this.login}
                        >Submit</ButtonForm>

                        <p>Don't have an account yet? <a href="/register">Register now</a></p>
                    </form>
                </div>
            </div>
        );
    }
}

export default LoginForm;