import React from 'react';
import { Redirect } from 'react-router';
import * as tools from '../Utils/general-tools';
import * as fetchRequest from '../Utils/fetch-request';

import './css/Practice.css';

import HeaderMenu from '../Components/HeaderMenu/HeaderMenu';
import Thumbnail from '../Components/Thumbnail/Thumbnail';

class Practice extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            multiplication: {
                min_table: {
                    title: "Minimum Table",
                    value: "--"
                },
                max_table: {
                    title: "Maximum Table",
                    value: "--"
                },
                nb_operations: {
                    title: "Operations",
                    value: "--"
                },
                max_duration: {
                    title: "Time",
                    value: "--:--"
                }
            },
            addition: {
                min_table: {
                    title: "Minimum Table",
                    value: "--"
                },
                max_table: {
                    title: "Maximum Table",
                    value: "--"
                },
                nb_operations: {
                    title: "Operations",
                    value: "--"
                },
                max_duration: {
                    title: "Time",
                    value: "--:--"
                }
            },
            substraction: {
                min_table: {
                    title: "Minimum Table",
                    value: "--"
                },
                max_table: {
                    title: "Maximum Table",
                    value: "--"
                },
                nb_operations: {
                    title: "Operations",
                    value: "--"
                },
                max_duration: {
                    title: "Time",
                    value: "--:--"
                }
            }
        }
    }

    componentDidMount() {
        const { token } = this.props;

        fetchRequest.getMultiplicationPrefs(
            token
        ).then(data => {
            let seconds = tools.numberToDigit(data.max_duration % 60);
            let minutes = tools.numberToDigit(parseInt(data.max_duration / 60));

            this.setState({
                multiplication: {
                    min_table: {
                        title: "Minimum Table",
                        value: data.min_table
                    },
                    max_table: {
                        title: "Maximum Table",
                        value: data.max_table
                    },
                    nb_operations: {
                        title: "Operations",
                        value: data.nb_operations
                    },
                    max_duration: {
                        title: "Time",
                        value: minutes + ":" + seconds
                    }
                }
            });
        }).catch(error => console.log('error', error));
    }

    render() {
        const { login, username } = this.props;
        const { multiplication, addition, substraction } = this.state;

        if (!login) {
            return <Redirect to={"/sign-in"} />
        }

        return (
            <React.Fragment>
                <HeaderMenu
                    handleLogout={this.props.handleLogout}
                    username={username}
                />

                <div className="container-practice">
                    <h2>Practice</h2>

                    <hr />

                    <h3>Select a category and start training!</h3>

                    <div className="container-category-section">
                        <Thumbnail
                            title={"Multiplication"}
                            description={"Train yourself by practicing! Try to solve all multiplications in a minimum of time!"}
                            url={"/practice-multiplication"}
                            preferences={multiplication}
                        />

                        <Thumbnail
                            title={"Addition"}
                            description={"[SOON] Train yourself by practicing! Try to solve all additions in a minimum of time!"}
                            url={"/practice"}
                            className="unavailable-content"
                            preferences={addition}
                        />

                        <Thumbnail
                            title={"Substraction"}
                            description={"[SOON] Train yourself by practicing! Try to solve all substractions in a minimum of time!"}
                            url={"/practice"}
                            className="unavailable-content"
                            preferences={substraction}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Practice;