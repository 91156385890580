import React from 'react';
import { Redirect } from 'react-router';

import HeaderMenu from '../Components/HeaderMenu/HeaderMenu';

class About extends React.Component {

    render() {
        const { login, username } = this.props;

        if (!login) {
            return <Redirect to={"/sign-in"} />
        }

        return (
            <React.Fragment>
                <HeaderMenu
                    handleLogout={this.props.handleLogout}
                    username={username}
                />
                <p>About</p>
            </React.Fragment>
        );
    }
}

export default About;