import React from 'react';
import { Redirect } from 'react-router';
import * as tools from '../Utils/general-tools';
import * as fetchRequest from '../Utils/fetch-request';

import alarm from '../Images/Icons/alarm.svg';
import calculator from '../Images/Icons/calculator.svg';

import './css/Multiplication.css';

import HeaderMenu from '../Components/HeaderMenu/HeaderMenu';
import Table from '../Components/Table/Table';

class Multiplication extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            min_table: "--",
            max_table: "--",
            nb_operations: "--",
            max_duration: "--:--",
            operations: [],
            timer: 0,
            start: false
        }
    }

    componentDidMount() {
        const { token } = this.props;

        fetchRequest.getMultiplicationPrefs(
            token
        ).then(data => {
            const operations = [];
            let seconds = tools.numberToDigit(data.max_duration % 60);
            let minutes = tools.numberToDigit(parseInt(data.max_duration / 60));

            for (let i = 0; i < data.nb_operations; i++) {
                const NumberA = tools.getRandomIntInclusive(data.min_table, data.max_table);
                const NumberB = tools.getRandomIntInclusive(data.min_table, data.max_table);

                operations.push({
                    operation: NumberA + " x " + NumberB + " = ",
                    a: NumberA,
                    b: NumberB
                });
            }

            this.setState({
                min_table: data.min_table,
                max_table: data.max_table,
                nb_operations: data.nb_operations,
                max_duration: minutes + ":" + seconds,
                timer: data.max_duration,
                operations: operations
            });
        }).catch(error => console.log('error', error));
    }

    handleStart = () => {
        this.setState({
            start: true
        });
    }

    /**
     * "operation_type": "multiplication",
     * "nb_right_answers": 20,
     * "nb_wrong_answers": 0,
     * "duration": 60,
     * "start_date": "2021-08-14 17:03:05.279 +00:00"
     */
    submitResult = (values, duration, startDate) => {
        const { token } = this.props;
        const { timer, nb_operations, operations } = this.state;

        let nb_right_answers = 0;
        let nb_wrong_answers = 0;

        for (let i = 0; i < operations.length; i++) {
            const result = operations[i].a * operations[i].b;
            const value = parseInt(values[i]);

            if (value === result) {
                nb_right_answers++;
            } else {
                nb_wrong_answers++;
            }
        }

        if (nb_right_answers + nb_wrong_answers !== nb_operations) {
            console.log("[ERROR] Number results didn't match with the number of operation.");
            return null;
        }

        const raw = JSON.stringify({
            operation_type: "multiplication",
            nb_right_answers: nb_right_answers,
            nb_wrong_answers: nb_wrong_answers,
            duration: (timer - duration),
            start_date: startDate.toISOString()
        });

        // console.log(raw);

        fetchRequest.createNewDataset(token, raw).then((data) => {
            console.log(data);
        });
    }

    render() {
        const { login, username } = this.props;
        const { nb_operations, max_duration, operations, timer, start } = this.state;

        if (!login) {
            return <Redirect to={"/sign-in"} />
        }

        return (
            <React.Fragment>
                <HeaderMenu
                    handleLogout={this.props.handleLogout}
                    username={username}
                />

                <div className="multiplication-content">
                    <h2>Multiplication</h2>

                    <hr />

                    {start ?

                        <Table
                            lines={operations}
                            max_duration={timer}
                            handleClick={this.submitResult}
                        />

                        :

                        <React.Fragment>
                            <h3>
                                On this test, you have to solve all multiplications displayed on the screen in a defined timing.
                            </h3>

                            <div className="container-prefs">
                                <div>
                                    <img src={alarm} alt={"icon-alarm"} />
                                    {max_duration}
                                </div>

                                <div>
                                    <img src={calculator} alt={"icon-calculator"} />
                                    {nb_operations}
                                </div>
                            </div>

                            <button onClick={() => this.handleStart()}>START THE TEST</button>
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default Multiplication;