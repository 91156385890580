import React from 'react';
import * as tools from '../../Utils/general-tools';
import * as fetchRequest from '../../Utils/fetch-request';

import personfill from '../../Images/Icons/person-fill.svg';
import keyfill from '../../Images/Icons/key-fill.svg';
import at from '../../Images/Icons/at.svg';

import InputIcon from '../InputIcon/InputIcon';
import ButtonForm from '../ButtonForm/ButtonForm';

import './RegisterForm.css';

class RegisterForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            successfullyRegistered: false,
            login: "",
            email: "",
            password: "",
            confirmPassword: ""
        };

        this.p = React.createRef();
    }

    register = () => {
        const { login, email, password, confirmPassword } = this.state;

        if (tools.isNull(login) | tools.isNull(email)
            | tools.isNull(password) | tools.isNull(confirmPassword)) {
            this.p.current.innerText = "All fields must be filled";
            return;
        }

        if (password !== confirmPassword) {
            this.p.current.innerText = "Passwords must be the same";
            return;
        }

        const details = {
            username: login,
            email: email,
            password: password
        };

        fetchRequest.register(details).then((data) => {
            if (data.status === "success") {
                this.setState({
                    successfullyRegistered: true
                });
            } else {
                this.p.current.innerText = data.response;
            }
        });
    }

    handleInput = (value, name) => {
        this.setState({
            [name]: value
        });
    }

    render() {
        const { successfullyRegistered, login, email, password, confirmPassword } = this.state;

        return (
            <div className="container">
                <div className="form-container">
                    {successfullyRegistered ?

                        <React.Fragment>
                            <h2>You have successfully registered</h2>
                            <p>Welcome to Learn Your Table!</p>

                            <p>You can now <a href="/sign-in">Sign in</a></p>
                        </React.Fragment>

                        :

                        <React.Fragment>
                            <h1>Register</h1>
                            <p>Fill to create an account</p>
                            <form>
                                <InputIcon
                                    name="login"
                                    type="text"
                                    value={login}
                                    icon={personfill}
                                    handleChange={this.handleInput}
                                >Login</InputIcon>
                                <InputIcon
                                    name="email"
                                    type="text"
                                    value={email}
                                    icon={at}
                                    handleChange={this.handleInput}
                                >Email address</InputIcon>
                                <InputIcon
                                    name="password"
                                    type="password"
                                    value={password}
                                    icon={keyfill}
                                    handleChange={this.handleInput}
                                >Password</InputIcon>
                                <InputIcon
                                    name="confirmPassword"
                                    type="password"
                                    value={confirmPassword}
                                    icon={keyfill}
                                    handleChange={this.handleInput}
                                >Confirm password</InputIcon>

                                <p ref={this.p} className="error-message"></p>

                                <ButtonForm
                                    handleClick={this.register}
                                >Submit</ButtonForm>

                                <p>Already have an account? <a href="/sign-in">Sign in</a></p>
                            </form>
                        </React.Fragment>
                    }
                </div>
            </div>

        );
    }
}

export default RegisterForm;