import React from 'react';

import './Thumbnail.css';

class Thumbnail extends React.Component {

    renderPrefs = (id) => {
        const { preferences } = this.props;
        let startId = id * 2;
        let endId = startId + 1;

        const rangePrefs = Object.keys(preferences).slice(startId, endId + 1);

        return <table key={id}>
            <tbody>
                {rangePrefs.map((keyName, index) => {
                    return <tr key={keyName + index}>
                        <td>{preferences[keyName].title} :</td>
                        <td>{preferences[keyName].value}</td>
                    </tr>
                })}
            </tbody>
        </table>;
    }

    render() {
        const { title, description, url, className, preferences } = this.props;
        const loop = [];
        const size = (Object.keys(preferences).length / 2) % 2 === 0 ?
            parseInt(Object.keys(preferences).length / 2)
            :
            parseInt(Object.keys(preferences).length / 2) + 1;

        for (let i = 0; i < size; i++)
            loop.push(0);

        return (
            <a className={"category-section " + (className !== undefined ? className : "")} href={url}>
                <h4>{title}</h4>

                <p>
                    {description}
                </p>

                <h5>Preferences :</h5>

                <div className="preferences-container">
                    {loop.map((value, index) => {
                        return this.renderPrefs(index);
                    })}
                </div>
            </a>
        );
    }
}

export default Thumbnail;