import { ONLY_NUMBER_REGEX } from '../const';

export function onlyNumbers(value) {
    if (value.match(ONLY_NUMBER_REGEX))
        return true;
    return false;
}

export function isNull(values) {
    switch (typeof values) {
        case "number":
            return values === 0 ? true : false;

        case "string":
            return values === "" ? true : false;

        case "object":
            if (Array.isArray(values)) {
                for (let i = 0; i < values.length; i++) {
                    if (isNull(values[i]))
                        return true;
                }
                return false;
            } else
                return values === {} ? true : false;

        default:
            return false;
    }
}

export function ObjectPropertiesAreStrings(object) {
    if (typeof object === "object") {
        for (let property in object) {
            if (typeof object[property] !== "string")
                return false;
        }
    }
    return true;
}

export function generateBody_xwwwformurlencoded(details) {
    if (ObjectPropertiesAreStrings(details)) {
        let formBody = [];

        for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        return formBody.join("&");
    }
    throw new Error("object parameters should be string");
}

export function logout() {
    localStorage.removeItem("token");
}

export function getToken() {
    return localStorage.getItem("token");
}

export function numberToDigit(value) {
    if (value < 10) {
        if (value === 0) {
            return "00";
        }
        return "0" + value;
    }
    return value;
}

export function getRandomIntInclusive(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}
