import React from 'react';
import { Redirect } from 'react-router';
import * as fetchRequest from '../Utils/fetch-request';

import './css/Settings.css';

import HeaderMenu from '../Components/HeaderMenu/HeaderMenu';
import Profile from './Settings/Profile';
import Preferences from './Settings/Preferences';

import {
    SETTING_PAGES
} from '../const';
import Account from './Settings/Account';

class Settings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            page: SETTING_PAGES.PROFILE.value,
        };
    }

    componentDidMount() {
        const { token } = this.props;

        fetchRequest.getProfil(
            token
        ).then(data => {
            if (data === null)
                this.handleLogout();
            else {
                this.setState({ username: data.username });
            }
        }).catch(error => console.log('error', error));;
    }

    renderPage = () => {
        const { token } = this.props;
        const { page } = this.state;

        switch (page) {
            case SETTING_PAGES.PROFILE.value:
                return <Profile
                    token={token}
                />

            case SETTING_PAGES.PREFS.value:
                return <Preferences
                    token={token}
                />

            case SETTING_PAGES.ACCOUNT.value:
                return <Account
                    token={token}
                    handleLogout={this.props.handleLogout}
                />


            default:
                break;
        }
    }

    handleClick = (e, keyName) => {
        e.preventDefault();

        this.setState({
            page: SETTING_PAGES[keyName].value
        })
    }

    render() {
        const { login, username } = this.props;
        const { page } = this.state;

        if (!login) {
            return <Redirect to={"/sign-in"} />
        }

        return (
            <React.Fragment>
                <HeaderMenu
                    handleLogout={this.props.handleLogout}
                    username={username}
                />
                <div className="container-settings">
                    <nav className="side-menu">
                        <ul>
                            {Object.keys(SETTING_PAGES).map((keyName, index) => {
                                return <a href="/settings" key={index} onClick={(e) => this.handleClick(e, keyName)}><li className={page === SETTING_PAGES[keyName].value ? "active-side-menu-field" : ""} >{SETTING_PAGES[keyName].tag}</li></a>
                            })}
                        </ul>
                    </nav>
                    <div className="content">{this.renderPage()}</div>
                </div>
            </React.Fragment>
        );
    }
}

export default Settings;