import React from 'react';
import { Redirect } from 'react-router';

import LoginForm from '../Components/LoginForm/LoginForm';

class SignIn extends React.Component {

    render() {
        const { login } = this.props;

        if (login) {
            return <Redirect to={"/"} />
        }

        return (
            <LoginForm handleLogin={this.props.handleLogin} />
        );
    }
}

export default SignIn;