import React from 'react';
import * as tools from '../../Utils/general-tools';

import alarm from '../../Images/Icons/alarm.svg';

import './Table.css';

import InputResult from '../InputResult/InputResult';

class Table extends React.Component {

    constructor(props) {
        super(props);

        const values = [];

        for (let i = 0; i < props.lines.length; i++) {
            values.push("");
        }

        this.state = {
            values: values,
            timer: props.max_duration,
            startDate: new Date()
        };

        this.interval = null;
    }


    componentDidMount() {
        this.interval = setInterval(this.getCenter, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getCenter = () => {
        const { timer } = this.state;

        if (timer > 0) {
            this.setState({
                timer: timer - 1
            });
        } else {
            clearInterval(this.interval);
        }
    }

    handleClick = () => {
        const { lines } = this.props;
        const { values, timer, startDate } = this.state;
        const allFill = values.some((value) => value.length === 0);

        for (let i = 0; i < lines.length; i++) {
            const result = lines[i].a * lines[i].b;
            const value = parseInt(values[i]);
            const inputTag = document.getElementsByTagName("input")[i];
            const resultTag = inputTag.parentNode.parentNode.lastChild;
            const buttonTag = document.getElementsByTagName("button")[0];

            if (!allFill | timer === 0) {
                resultTag.classList.remove("notFill");

                if (value === result) {
                    resultTag.parentNode.style.color = "#77B255";
                    inputTag.style.color = "rgb(119, 178, 85)";
                } else {
                    resultTag.parentNode.style.color = "#FF0000";
                    resultTag.innerHTML = result;
                    inputTag.style.color = "rgb(255, 0, 0)";
                }

                inputTag.style.backgroundColor = "rgba(255, 255, 255, 1)";
                inputTag.readOnly = true;
                buttonTag.disabled = true;
                clearInterval(this.interval);

            } else {
                resultTag.classList.remove("notFill");
                resultTag.innerHTML = "";

                if (values[i].length === 0) {
                    resultTag.classList.add("notFill");
                    resultTag.innerHTML = "Not filled!";
                }
            }
        }

        if (!allFill | timer === 0) {
            this.props.handleClick(values, timer, startDate);
        }
    }


    

    setValueByIndex = (value, index) => {
        const { values } = this.state;
        values[index] = value;

        this.setState({
            values: values
        });
    }

    generateInputsNumber = () => {
        const { lines } = this.props;
        const { values } = this.state;

        return <table className="calculs-table">
            <tbody>
                {values.map((element, index) => {
                    return <tr key={index}>
                        <td>
                            {lines[index].operation}
                        </td>
                        <td>
                            <InputResult
                                index={index}
                                value={element}
                                setValue={this.setValueByIndex}
                            />
                        </td>
                        <td></td>
                    </tr>
                })}
            </tbody>
        </table>
    }

    render() {
        const { timer } = this.state;

        if (timer === 0)
            this.handleClick();

        return (
            <div className="table-content">
                <div className="timer">
                    <img src={alarm} alt={"icon-alarm"} />
                    {tools.numberToDigit(parseInt(timer / 60)) + ":" + tools.numberToDigit(timer % 60)}
                </div>

                {this.generateInputsNumber()}
                <button onClick={() => this.handleClick()} disabled={timer > 0 ? false : true}>Corriger</button>
            </div>
        );
    }
}

export default Table;
