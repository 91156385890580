import React from 'react';
import * as fetchRequest from '../../Utils/fetch-request';

import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import UpdateForm from '../../Components/UpdateForm/UpdateForm';

class Profile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            editPasswordMessage: {
                style: { color: "green" },
                content: ""
            }
        };
    }

    submitUpdatedPassword = (data) => {
        const { token } = this.props;
        const password = data[0]
        const newPassword = data[1];
        const confirmNewPassword = data[2];

        if (password === "" | newPassword === "" | confirmNewPassword === "") {
            this.setState({
                editPasswordMessage: {
                    style: { color: "red" },
                    content: "All fields must be filled"
                }
            })
            return;
        }

        if (newPassword !== confirmNewPassword) {
            this.setState({
                editPasswordMessage: {
                    style: { color: "red" },
                    content: "New password / confirmation must be the same."
                }
            })
            return;
        }

        const raw = JSON.stringify({
            password: password,
            newPassword: newPassword
        });

        fetchRequest.updatePassword(
            token, raw
        ).then(data => {
            if (data.success) {
                this.setState({
                    editPasswordMessage: {
                        style: { color: "green" },
                        content: data.success
                    }
                });
            } else {
                this.setState({
                    editPasswordMessage: {
                        style: { color: "red" },
                        content: data.error
                    }
                });
            }

        }).catch(error => console.log('error', error));
    }

    render() {
        const { editPasswordMessage } = this.state;

        return (
            <React.Fragment>
                <Breadcrumb
                    path={"User Settings > "}
                    name={"Edit Profile"}
                />

                <hr />

                <UpdateForm
                    title="Edit password"
                    description="Update your password by filling all fields :"
                    message={editPasswordMessage}
                    formOnSubmit={true}
                    form={[
                        {
                            type: "password",
                            label: "Password",
                            description: "your current password."
                        },
                        {
                            type: "password",
                            label: "New password",
                            description: "password you want to replace with."
                        },
                        {
                            type: "password",
                            label: "Confirm new password",
                            description: "confirm your new password."
                        }
                    ]}
                    handleClick={this.submitUpdatedPassword}
                />

            </React.Fragment>
        );
    }
}

export default Profile;