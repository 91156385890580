// export const API_ENDPOINT = "http://localhost:8080"
export const API_ENDPOINT = "https://learn-your-tables.thezelais.com"
export const DEFAULT_NB_OPERATIONS = 10;
export const DEFAULT_MAX_TABLE = 12;
export const ONLY_NUMBER_REGEX = /^[0-9]+$|^$/;
export const SETTING_PAGES = {
    PROFILE: {
        tag: "Profile",
        value: 0
    },
    PREFS: {
        tag: "Preferences",
        value: 1
    },
    ACCOUNT: {
        tag: "Account",
        value: 2
    }
};