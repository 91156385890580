import React from 'react';
import { Redirect } from 'react-router';

import RegisterForm from '../Components/RegisterForm/RegisterForm';

class Register extends React.Component {

    render() {
        const { login } = this.props;

        if (login) {
            return <Redirect to={"/"} />
        }

        return (
            <RegisterForm />
        );
    }
}

export default Register;