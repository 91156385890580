import React from 'react';

import './InputResult.css';

class InputResult extends React.Component {

    checkValue = (value) => {
        if (value.match(/^[0-9]+$|^$/))
            return true;
        return false;
    }

    handleChange = (e) => {
        const { index } = this.props;
        const value = e.target.value;

        if (this.checkValue(value))
            this.props.setValue(value, index);
    }

    render() {
        const { value } = this.props;

        return <React.Fragment>
            <input className="input-result" type="text" maxLength={5} onChange={(e) => this.handleChange(e)} value={value} />
        </React.Fragment>
    }
}

export default InputResult;