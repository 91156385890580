import React from 'react';

import './ButtonForm.css';

class ButtonForm extends React.Component {

    handleClick = (e) => {
        e.preventDefault();

        this.props.handleClick();
    }

    render() {
        const { children } = this.props;

        return (
            <div className="button-container">
                <button type="submit" onClick={(e) => this.handleClick(e)}>{children}</button>
            </div>
        );
    }
}

export default ButtonForm;