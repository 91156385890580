import React from 'react';
import * as tools from '../../Utils/general-tools';
import * as fetchRequest from '../../Utils/fetch-request';

import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import UpdateForm from '../../Components/UpdateForm/UpdateForm';

class Account extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            deleteAccountMessage: {
                style: { color: "green" },
                content: ""
            },
            displayRedirectPopUp: false
        };
    }

    submitDeleteOperation = (data) => {
        const { token } = this.props;
        const password = data[0];

        if (password === "") {
            this.setState({
                deleteAccountMessage: {
                    style: { color: "red" },
                    content: "All fields must be filled"
                }
            })
            return;
        }

        const raw = JSON.stringify({
            password: password
        });

        fetchRequest.deleteAccount(
            token, raw
        ).then(data => {
            if (data.success) {
                this.setState({
                    displayRedirectPopUp: true
                });
                tools.logout();
            } else {
                this.setState({
                    deleteAccountMessage: {
                        style: { color: "red" },
                        content: data.error
                    }
                });
            }

        }).catch(error => console.log('error', error));
    }

    render() {
        const { deleteAccountMessage, displayRedirectPopUp } = this.state;

        return (
            <React.Fragment>
                <Breadcrumb
                    path={"User Settings > "}
                    name={"Account"}
                />

                <hr />

                <UpdateForm
                    title="Delete Account"
                    description="Delete your account (this operation is irreversible) :"
                    message={deleteAccountMessage}
                    formOnSubmit={true}
                    form={[
                        {
                            type: "password",
                            label: "Password",
                            description: "enter your password before submitting to confirm the operation.",
                        }
                    ]}
                    buttonStyle={"delete-button"}
                    handleClick={this.submitDeleteOperation}
                />

                {displayRedirectPopUp &&
                    <div className="popup-container-background">
                        <div className="popup-container">
                            <h2>Your account has been successfully removed</h2>
                            <p className="popup-description">We hope to see you again soon!</p>

                            <p className="popup-text-redirection">Click to be redirect on the <a href="/sign-in">Login page</a></p>
                        </div>
                    </div>
                }

            </React.Fragment>
        );
    }
}

export default Account;