import React from 'react';
import * as tools from './Utils/general-tools';
import * as fetchRequest from './Utils/fetch-request';

import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom';

import './App.css';

import Home from './Pages/Home';
import Practice from './Pages/Practice';
import Multiplication from './Pages/Multiplication';
import Settings from './Pages/Settings';
import About from './Pages/About';
import SignIn from './Pages/SignIn';
import Register from './Pages/Register';

class App extends React.Component {

    constructor(props) {
        super(props);

        let login = false;
        let token = tools.getToken();

        if (token !== null)
            login = true;

        this.state = {
            login: login,
            token: token,
            username: ""
        };
    }

    componentDidMount() {
        const { login, token } = this.state;

        if (login) {
            fetchRequest.getProfil(
                token
            ).then(data => {
                if (data === null)
                    this.handleLogout();
                else {
                    this.setState({ username: data.username });
                }
            }).catch(error => console.log('error', error));;
        }
    }

    handleLogout = () => {
        tools.logout();
        this.setState({
            login: false,
            token: null,
            username: ""
        });
    }

    handleLogin = (token) => {
        this.setState({
            login: true,
            token: token
        });
        this.componentDidMount();
    }

    render() {
        const { login, username, token } = this.state;

        return (
            <Router>
                <Route path="/" exact render={() => <Home login={login} username={username} handleLogout={this.handleLogout} />} />
                <Route path="/sign-in" render={() => <SignIn login={login} handleLogin={this.handleLogin} />} />
                <Route path="/register" render={() => <Register login={login} handleRegister={this.handleRegister} />} />
                <Route path="/practice" component={() => <Practice login={login} username={username} token={token} handleLogout={this.handleLogout} />} />
                <Route path="/practice-multiplication" component={() => <Multiplication login={login} username={username} token={token} handleLogout={this.handleLogout} />} />
                <Route path="/settings" component={() => <Settings login={login} username={username} token={token} handleLogout={this.handleLogout} />} />
                <Route path="/about" component={() => <About login={login} username={username} handleLogout={this.handleLogout} />} />
            </Router>
        );
    }
}

export default App;
