import React from 'react';

import './InputIcon.css';

class InputIcon extends React.Component {
    
    handleChange = (e) => {
        const { name } = this.props;

        const value = e.target.value;
        this.props.handleChange(value, name);
    }

    render() {
        const { icon, name, type, children } = this.props;

        return (
            <div className="field-container" >
                <img src={icon} alt={"icon-" + name} />
                <input type={type} name={name} onChange={this.handleChange} placeholder={children} />
            </div>
        );
    }
}

export default InputIcon;