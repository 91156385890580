import React from 'react';
import * as fetchRequest from '../../Utils/fetch-request';

import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import UpdateForm from '../../Components/UpdateForm/UpdateForm';

class Preferences extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            multiplicationPrefsMessage: {
                style: { color: "green" },
                content: ""
            },
            multiplicationValues: {
                min_table: 0,
                max_table: 0,
                nb_operations: 0,
                max_duration: 0
            }
        };
    }

    componentDidMount() {
        const { token } = this.props;

        fetchRequest.getMultiplicationPrefs(
            token
        ).then(data => {
            this.setState({
                multiplicationValues: {
                    min_table: data.min_table,
                    max_table: data.max_table,
                    nb_operations: data.nb_operations,
                    max_duration: data.max_duration
                }
            });
        }).catch(error => console.log('error', error));
    }

    handleChange = (value, keyName) => {
        const { multiplicationValues } = this.state;
        const newMultiplicationValues = multiplicationValues;

        Object.keys(multiplicationValues).map((key) => {
            if (key === keyName) {
                newMultiplicationValues[key] = value;
            }
            return null;
        });

        this.setState({
            multiplicationValues: newMultiplicationValues
        });
    }

    submitUpdatedMultiplicationPrefs = () => {
        const { token } = this.props;
        const { min_table, max_table, nb_operations, max_duration } = this.state.multiplicationValues;

        const raw = JSON.stringify({
            min_table: min_table,
            max_table: max_table,
            nb_operations: nb_operations,
            max_duration: max_duration
        });

        fetchRequest.updateMultiplicationPrefs(
            token, raw
        ).then(data => {
            if (data.success) {
                this.setState({
                    multiplicationPrefsMessage: {
                        style: { color: "green" },
                        content: data.success
                    }
                });
            } else {
                this.setState({
                    multiplicationPrefsMessage: {
                        style: { color: "red" },
                        content: data.error
                    }
                });
            }
        }).catch(error => console.log('error', error));
    }

    render() {
        const { multiplicationPrefsMessage, multiplicationValues } = this.state;

        return (
            <React.Fragment>
                <Breadcrumb
                    path={"User Settings > "}
                    name={"Edit Preferences"}
                />

                <hr />

                <UpdateForm
                    title="Multiplication Preferences"
                    description="Configure your multiplication preferences here :"
                    message={multiplicationPrefsMessage}
                    formOnSubmit={false}
                    form={[
                        {
                            type: "number",
                            keyName: "min_table",
                            label: "Minimum table",
                            description: "first table multiplication table (included) to use.",
                            value: multiplicationValues.min_table
                        },
                        {
                            type: "number",
                            keyName: "max_table",
                            label: "Maximum table",
                            description: "last table multiplication table (included) to use.",
                            value: multiplicationValues.max_table
                        },
                        {
                            type: "number",
                            keyName: "nb_operations",
                            label: "Number of operations",
                            description: "number of operations/calculs per series.",
                            value: multiplicationValues.nb_operations
                        },
                        {
                            type: "number",
                            keyName: "max_duration",
                            label: "Series duration",
                            description: "the maximum time you have to solve all operations per series (in secondes).",
                            value: multiplicationValues.max_duration
                        }
                    ]}
                    handleChange={this.handleChange}
                    handleClick={this.submitUpdatedMultiplicationPrefs}
                />

            </React.Fragment>
        );
    }
}

export default Preferences;