import React from 'react';

import './InputNumber.css';

class InputNumber extends React.Component {

    increaseValue = () => {
        const { value, keyName } = this.props;

        this.props.handleClick(value + 1, keyName);
    }

    decreaseValue = () => {
        const { value, keyName } = this.props;

        this.props.handleClick(value - 1, keyName);
    }

    render() {
        const { value, name, label } = this.props;

        if (value === undefined & value === null) {
            return (
                <div>Unknown input type</div>
            );
        }

        return (
            <div className="number-field-container">
                <label htmlFor={name}>{label}</label>
                <div>
                    <button onClick={() => this.increaseValue()}>+</button>
                    <span className="value-container">{value}</span>
                    <button onClick={() => this.decreaseValue()}>-</button>
                </div>
            </div>
        );
    }
}

export default InputNumber;