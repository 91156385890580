import React from 'react';

import './Breadcrumb.css';

class Breadcrumb extends React.Component {

    render() {
        const { path, name } = this.props

        return (
            <section className="breadcrumb">
                <h4><span>{path}</span><span>{name}</span></h4>
            </section>
        );
    }
}

export default Breadcrumb;